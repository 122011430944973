import React from 'react';
import PropTypes from 'prop-types';

import { getCategoryColor } from 'common/helpers';
import CategoryStyled from './styles';

const BlogCategory = ({ children, slug, to, key, color, background, border, active = false }) => {
  const categoryColor = getCategoryColor(slug);
  let borderColor;

  if (active) {
    background = categoryColor;
    color = 'white';
    borderColor = categoryColor;
  }

  return (
    <CategoryStyled
      color={color}
      background={background}
      border={border}
      borderColor={borderColor}
      hover={categoryColor}
      to={to}
      key={key}
    >
      {children}
    </CategoryStyled>
  );
};

BlogCategory.propTypes = {
  slug: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  border: PropTypes.bool.isRequired,
};

export default BlogCategory;
