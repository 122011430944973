import styled from 'styled-components';
import Link from 'atomic-design/atoms/link';

const CategoryStyled = styled(Link)`
  color: ${props => props.color};
  padding: ${({ theme }) => `${theme.newTheme.spacing(2)} ${theme.newTheme.spacing(6)}`};
  background: ${props => props.background};
  border: ${props => (props.border ? '1px solid' : '0')};
  border-color: ${props => (props.borderColor ? props.borderColor : props.color)};
  border-radius: ${props => (props.border ? '999px' : '0')};
  transition: ${({ theme }) => theme.newTheme.transition.main};
  cursor: pointer;

  font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};

  &:hover {
    text-decoration: ${props => (props.border ? 'none' : 'underline')};
    background: ${props => props.hover};
    color: ${({ theme }) => theme.newTheme.color.white};
  }
`;

export default CategoryStyled;
