import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  flex-wrap: wrap;
  padding: ${props => props.padding};
  width: 100%;
  gap: 1em;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding: ${({ theme }) => `0 0 ${theme.newTheme.spacing(12)} 0`};
  }
`;

export const DropdownButton = styled.button`
  width: 100%;
  background: white;
  border-radius: ${({ theme }) => `${theme.newTheme.border.radius.main}`};
  border: 2px solid;
  border-color: ${({ color }) => color};
`;

export const DropdownList = styled.div`
  a {
    width: 100%;
  }

  li {
    padding: ${({ theme }) =>
      `${theme.newTheme.spacing(4)} ${theme.newTheme.spacing(5)} !important`};
  }

  div {
    margin: ${({ theme }) => `-${theme.newTheme.spacing(1)} 0 0 0`};
  }
`;

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.newTheme.spacing(3)} ${theme.newTheme.spacing(2)}`};

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    color: ${({ color }) => color};
  }
`;
