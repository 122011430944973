import React, { useState } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import BlogCategory from 'atomic-design/atoms/blog-category';
import Dropdown from 'atomic-design/molecules/dropdown';
import useIsMobile from 'hooks/useIsMobile';
import { getCategoryColor, getCategoryArray, getCurrentPath } from 'common/helpers';

import { ChevronArrow } from 'ui/svg';
import { Container, DropdownButton, ButtonContent, DropdownList } from './styles';

const BlogCategories = ({ showAll, border, padding }) => {
  const isMobile = useIsMobile('mobile');
  const [show, setShow] = useState(false);
  const currentPath = getCurrentPath();

  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPostCategory(sort: { fields: order, order: ASC }) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `);

  let activeCategory;
  data.allContentfulBlogPostCategory.edges.forEach(edge => {
    if (currentPath.includes(edge.node.slug)) {
      activeCategory = edge.node.name;
    }
  });
  if (!activeCategory) {
    activeCategory = 'All';
  }

  return (
    <Container padding={padding}>
      {isMobile ? (
        <>
          <DropdownButton
            onClick={() => setShow(!show)}
            color={getCategoryColor(activeCategory.toLowerCase())}
          >
            <ButtonContent color={getCategoryColor(activeCategory.toLowerCase())}>
              <p>{activeCategory}</p>
              <ChevronArrow color={getCategoryColor(activeCategory.toLowerCase())} />
            </ButtonContent>
          </DropdownButton>

          <DropdownList onClick={() => setShow(false)}>
            <Dropdown
              itemList={[
                {
                  label: '',
                  links: getCategoryArray(data.allContentfulBlogPostCategory.edges),
                },
              ]}
              isShow={show}
            />
          </DropdownList>
        </>
      ) : (
        <>
          {showAll && (
            <BlogCategory
              to="/blog"
              slug="all"
              color={getCategoryColor('all')}
              border={border}
              background={border ? 'white' : getCategoryColor('all')}
              active={activeCategory === 'All'}
            >
              All
            </BlogCategory>
          )}
          {data.allContentfulBlogPostCategory.edges.map(edge => (
            <BlogCategory
              key={edge.node.slug}
              to={`/blog/category/${edge.node.slug}`}
              slug={edge.node.slug}
              color={border ? getCategoryColor(edge.node.slug) : 'white'}
              border={border}
              background={border ? 'white' : getCategoryColor(edge.node.slug)}
              active={edge.node.name === activeCategory}
            >
              {edge.node.name}
            </BlogCategory>
          ))}
        </>
      )}
    </Container>
  );
};

BlogCategories.propTypes = {
  showAll: PropTypes.bool,
  border: PropTypes.bool,
  padding: PropTypes.string,
};

BlogCategories.defaultProps = {
  showAll: false,
  border: false,
};

export default BlogCategories;
