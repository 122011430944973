import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import Pagination from 'atomic-design/organisms/pagination';
import BlogEntries from 'atomic-design/organisms/blog-entries';
import BlogCategories from 'atomic-design/molecules/blog-categories';
import { getAbsoluteUrl } from 'common/helpers';
import CategoryOpenGraph from 'static/images/metatags/category-open-graph.jpg';
import theme from 'styles/theme';
import PageHeader from 'atomic-design/atoms/page-header';
import PageSection from 'atomic-design/atoms/page-section';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const categoryListQuery = graphql`
  query categoryListQuery($slug: String!, $skip: Int!, $limit: Int!) {
    allContentfulPage(filter: { url: { eq: "blog" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
        }
      }
    }

    allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
      filter: { category: { slug: { eq: $slug } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          subtitle
          slug
          publishedDate
          excerpt {
            excerpt
          }
          category {
            name
            slug
          }
          hero {
            contentful_id
          }
        }
      }
    }
  }
`;
const Category = ({ pageContext, location, data }) => {
  const { currentPage, numPages, currentCategory } = pageContext;
  const pageHeaderData = data.allContentfulPage.edges[0].node;

  return (
    <Layout path={location.pathname} bodyType="xWide">
      <MetaTags
        title={`${currentCategory.name} Archives - NaNLABS`}
        description={currentCategory.description}
        charSet="utf-8"
        image={getAbsoluteUrl(CategoryOpenGraph)}
        viewport="width=device-width, initial-scale=1, shrink-to-fit=no"
        type="website"
      />
      <PageSection>
        <PageHeader
          title={pageHeaderData.headerSubtitle}
          subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
          color={theme.newTheme.color.gradient.text}
        />
      </PageSection>
      <PageSection padding={`0 0 ${theme.newTheme.spacing(22)} 0`} mobilePadding="0">
        <BlogCategories showAll border />
      </PageSection>

      <PageSection
        padding={`${theme.newTheme.spacing(5)} 0 ${theme.newTheme.spacing(20)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(15)} 0`}
      >
        <BlogEntries posts={data.allContentfulBlogPost.edges} />
      </PageSection>
      <PageSection margin={`${theme.newTheme.spacing(4)} 0 ${theme.newTheme.spacing(12)} 0`}>
        <Pagination
          to={`/blog/category/${currentCategory.slug}`}
          toPage={`/blog/category/${currentCategory.slug}/page/`}
          numPages={numPages}
          currentPage={currentPage}
        />
      </PageSection>
    </Layout>
  );
};

Category.propTypes = {
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  currentCategory: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};

Category.defaultProps = {
  currentPage: null,
  numPages: null,
  currentCategory: null,
};

export default Category;
